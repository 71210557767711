import request, { request as http } from '@/utils/request'
import { AxiosPromise } from 'axios'
// 金融项目

//项目列表
export function getFinancingList(data: Object) {
  return request({
    url: '/directionalFinancing/page',
    method: 'POST',
    data: data
  })
}
//项目详情
export function getFinancingDetail(data: Object) {
  return request({
    url: '/directionalFinancing/getDirectionalFinancingDetail',
    method: 'POST',
    data: data
  })
}

//获取干系人
export function getStakeholder(data: Object) {
  return request({
    url: '/directionalFinancing/stakeholderPage',
    method: 'POST',
    data: data
  })
}

//提交缴费通知书
export function sumbitPaymentNotice(data: Object) {
  return request({
    url: '/directionalFinancing/uploadPaymentNotice',
    method: 'POST',
    data: data
  })
}

// 项目公开设置
export function setOpenStatus(data: Object) {
  return request({
    url: '/directionalFinancing/updatePublicStatus',
    method: 'POST',
    data: data
  })
}

// 保存项目备案登记号
export function preserveRecordNo(data: Object) {
  return request({
    url: '/directionalFinancing/modifyProjectNum',
    method: 'POST',
    data: data
  })
}

//项目详情-主动摘牌
export function activeDelisting(data: Object) {
  return request({
    url: '/directionalFinancing/activeDelisting',
    method: 'POST',
    data: data
  })
}

// 财务管理列表
export function getFinancingLists(data: Object) {
  return request({
    url: '/directionalFinancingCost/getFinancialManagement',
    method: 'POST',
    data: data
  })
}
// 财务管理详情
export function getFinancingDetails(data: Object) {
  return request({
    url: '/directionalFinancingCost/selectFinancialManagementById',
    method: 'POST',
    data: data
  })
}


// 财务管理--确认收款
export function handleConfirmReceipt(data: Object) {
  return request({
    url: '/directionalFinancingCost/confirmPayment',
    method: 'POST',
    data: data
  })
}
// 财务管理--确认清分
export function handleConfirmClarify(data: Object) {
  return request({
    url: '/directionalFinancingCost/confirmAndClearing',
    method: 'POST',
    data: data
  })
}
// 财务管理-驳回
export function goReject(data: Object) {
  return request({
    url: '/directionalFinancingCost/reject',
    method: 'POST',
    data: data
  })
}

// 财务管理--修改缴费凭证
export function handleUpdateCertificate(data: Object) {
  return request({
    url: '/directionalFinancingCost/updateCertificate',
    method: 'POST',
    data: data
  })
}

// 交易记录
export function transactionList(data: Object) {
  return request({
    url: '/settlementTransaction/getRecord',
    method: 'POST',
    data: data
  })
}

// 交易详情
export function transactionDetails(data: Object) {
  return request({
    url: '/settlementTransaction/getTransactionDetail',
    method: 'POST',
    data: data
  })
}

// 收款人列表
export function settlementTradeRecordBatchDetail(data: Object) {
  return request({
    url: '/settlementTransaction/getRecordDetail',
    method: 'POST',
    data: data
  })
}

// 审批列表------------------
export function approvalManagementList(type: string) {
  return request({
    url: `/admin/approval/list/${type}`,
    method: 'GET',
  })
}
// 查看审批设置------------------
export function checkApprovalSetting(data: any) {
  return request({
    url: '/admin/approval/ruleSetting/get',
    method: 'POST',
    data,
  })
}
// 提交审批----------------
export function handleApprovalRule(data: any) {
  return request({
    url: '/admin/approval/ruleSetting/submit',
    method: 'POST',
    data,
  })
}
// 获取所有的审批
export function allApprovalPerson() {
  return request({
    url: '/admin/person/getAll',
    // method: 'GET'
    method: 'POST'
  })
}

// -------备案企业管理-------
// 企业列表----------------
export function handleEnterprisePage(data: any) {
  return request({
    url: '/zct/directionalFinancingResponse/page',
    method: 'POST',
    data,
  })
}
// 企业参与项目----------------
export function handleEnterpriseFinancingPage(data: any) {
  return request({
    url: '/zct/directionalFinancingResponse/financingPage',
    method: 'POST',
    data,
  })
}
// 企业基本信息----------------
export function handleEnterpriseDetail(data: any) {
  return request({
    url: '/zct/directionalFinancingResponse/detail',
    method: 'POST',
    data,
  })
}

// -------结算费用-------
// 结算确认(确认结算，确认并结算，确认收款，驳回)
export function settlemCentConfirm(data: any) {
  return request({
    url: '/directionalFinancingCost/settlement/approve',
    method: 'POST',
    data,
  })
}

// 结算明细分页
export function settlementInfoPage(data: any) {
  return request({
    url: '/directionalFinancingCost/settlement/detailPage',
    method: 'POST',
    data,
  })
}

// 结算缴费信息
export function settlementPayInfo(data: any) {
  return request({
    url: '/directionalFinancingCost/settlement/payDetail',
    method: 'POST',
    data,
  })
}

// 替换缴费凭证地址
export function replaceVoucher(data: any) {
  return request({
    url: '/directionalFinancingCost/settlement/updatePayUrl',
    method: 'POST',
    data,
  })
}
