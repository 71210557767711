
import { Component, Vue } from 'vue-property-decorator'
import { settlementTradeRecordBatchDetail } from '@/api/financing'
import timeFormat from '@/utils/timeFormat'

@Component({
  components: {},
  filters: {
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    }
  }
})
export default class tradeReceipt extends Vue {
  private tableData: any = []
  private interval: number = 0
  private seal = require('@/assets/images/certificate_seal.png')
  private obj: object = {}
  private loading = false
  private userName: String = this.$store.state.userName || sessionStorage.getItem('UsIF')
  private date: String = timeFormat(+new Date())
  created() {
    if (this.userName) {
      this.userName = this.userName.replace(/\"/g, '')
    }
    if (this.$route.params.obj) {
      this.obj = JSON.parse(this.$route.params.obj as any)
      sessionStorage.setItem('tradeReceipt', this.$route.params.obj)
    } else {
      this.obj = JSON.parse(sessionStorage.getItem('tradeReceipt') as any)
    }
    this.query()
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    sessionStorage.removeItem('tradeReceipt')
    next()
  }
  get filterTable() {
    let returnTable: any = []
    if (this.tableData && this.tableData.length) {
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i] && this.tableData[i].status == 2) {
          returnTable.push(this.tableData[i])
        }
      }
    }
    return returnTable
  }
  async query() {
    this.loading = true
    settlementTradeRecordBatchDetail({ transactionId: this.$route.params.id })
      .then((res) => {
        if (res.data.data) {
          this.tableData = res.data.data
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  clickprinting() {
    ;(this.$refs.btn as any).$el.style.display = 'none'
    let print = this.$refs.print
    //获取目标区域的HTML结构
    document.body.innerHTML = (print as any).innerHTML //替换网页的html内容
    this.interval = window.setInterval(this.printOperation, 500)
  }

  private printOperation() {
    window.print() //打印
    window.location.reload() //刷新页面，因为网页的html被替换后很丑
    window.clearInterval(this.interval)
  }
}
